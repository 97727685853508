import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import styles from './styles.module.scss';
import Grid from '../../common/Grid';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/crowdworks/1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/crowdworks/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/crowdworks/3.webp" }) {
      ...fluidImage
    }
  }
  `;

const CrowdWorks = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = [
    'Konseptutvikling',
    'Grafisk design',
    'UX / UI'];

  return (
    <Layout className={styles.container}>
      <TopSection project={"Ny merkevare og identitet for CrowdWorks"} deliveries={deliveries}>
        <p>
          <b>CrowdWorks</b>  Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
        </p>
      </TopSection>
      <Grid className={styles.grid}>
        <div className={styles.smallImageWrapper}>
          <NonStretchedImage className={styles.image} {...data.image1.childImageSharp} />
        </div>
      </Grid>
      <TextBlock header={"Om prosjektet"}>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros.
          Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
        </p>
        <p>
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
          Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
          Etiam porta sem malesda magna mollis euismod.
        </p>
      </TextBlock>
      <Grid>
        <NonStretchedImage className={styles.imageWrapper} {...data.image2.childImageSharp}/>
      </Grid>

      <Grid className={styles.white}>
        <div className={styles.smallImageWrapper}>
          <NonStretchedImage className={styles.image} {...data.image3.childImageSharp}/>
        </div>
      </Grid>
      <Nav/>
    </Layout>
  )
};

export default CrowdWorks;

