import React from 'react';
import CrowdWorks from '../components/cases/CrowdWorks';

const Page = (): JSX.Element => (
  <>
    <CrowdWorks />
  </>
);

export default Page;
